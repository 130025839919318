<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 加油订单
 * @Date: 2020-12-30 17:43:18
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-08 16:31:42
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/comeon.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="type" style="border-top: 1px solid #eee;" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div class="orderlist">
          <ul>
            <li v-for="(item,index) in orderList" :key="index">
              <div>
                <span>{{ item.titleRe }}</span>
                <span
                  :style="{color: item.status == 11 || item.status == 20 ?'': item.status == 25 ? '#999999':'#E33F44' }"
                >
                  {{ item.statusText }}
                </span>
              </div>
              <div class="sp">数量：{{ item.statusText }}</div>
              <div class="sp">油号/枪号：{{ item.name }}{{ item.gun_no }}</div>
              <div class="sp2">
                <span>提交时间：{{ item.time }}</span>
                <span>支付金额:{{ item.price }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!orderList.length">
          <van-empty description="暂无数据" />
        </div>
        <div v-show="false" id="dataList" />
      </div>
    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Tab, Tabs } from 'vant'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Empty)
import { getorderlist } from '@/services/comeOn.js'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'Comeon',
  components: {
    mescroll
  },
  data() {
    return {
      mescrollDown: {
        use: false,
        bottom: '0',
        top: '50px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      },
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      orderList: [],
      tablist: [
        { name: '全部' },
        { name: '已支付' },
        { name: '退款' }
      ]
    }
  },
  created() {
    this.getinfo()
  },
  mounted() {
  },
  methods: {

    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      this.getinfo(page.num, page.size, (arr) => {
        if (page.num === 1) this.orderList = []
        this.orderList = this.orderList.concat(arr)
        this.$nextTick(() => {
          mescroll.endSuccess(arr.length)
        })
      }, () => {
        mescroll.endErr()
      })
    },
    getinfo(pageNum, pageSize, successCallback, errorCallback) {
      const prom = {
        // payStauts:this.type,
        status: this.type,
        page: this.page,
        pageSize: this.pageSize,
        orderType: 901,
        mid: window.localStorage.getItem('uid')
      }
      getorderlist(prom).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
          this.page++
        } else {
          errorCallback(res.msg)
        }
      })
    },
    tabkey() {
      this.page = 1
      this.orderList = []
      this.mescroll.resetUpScroll()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .orderlist {
    & > ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;

      & > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span:nth-child(1) {
          font-weight: 500;
        }
      }

      .sp {
        text-align: left;
        color: #999999;
        margin-top: 10px;
      }

      .sp2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        & > span:nth-child(1) {
          color: #999999;
        }

        & > span:nth-child(2) {
          color: #333333;
        }
      }
    }
  }
}
</style>
